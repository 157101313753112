.fresler-table-container {
    position: relative;
    border-top: 1px solid rgba(0, 0, 0, 0.08);
    max-width: 98%;
    height: 96%;
    width: fit-content;
    border-bottom: none;
}

.fresler-table-container-internal {
    position: relative;
    width: 100%;
    height: 100%;
}

.fresler-table-container-col-data-section {
    position: relative;
    width: 100%;
    height: 97%;
}

.fresler-table-container-col-data-section-internal {
    position: relative;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    position: relative;
    z-index: 1;
}

.fresler-table-row-col.fresler-table-column-header-row {
    background-color: rgba(0, 0, 0, 0.03);
    border-top: 1px solid rgba(0, 0, 0, 0.08);
    border-bottom: 1px solid black;
    /* padding-right: 15px; */
    max-height: 5em;
    z-index: 1000;
    overflow: hidden;
    flex-shrink: 0;
}

.fresler-table-row {
    border-left: 1px solid rgba(0, 0, 0, 0.08);
    display: flex;
    width: fit-content;
}


.fresler-table-row-container {
    position: relative;
    z-index: 2;
    flex-grow: 1;
    overflow-y: auto;
    overflow-x: auto;
}

.fresler-table-container-search-section {
    border-left: 1px solid rgba(0, 0, 0, 0.08);
    border-right: 1px solid rgba(0, 0, 0, 0.08);
    display: flex;
    flex: "0 0 auto";
    width: 100%;
}

.fresler-grouped-table {
    border: 1px solid rgba(0, 0, 0, 0.08);
}


.resized {
    text-overflow: ellipsis;
    white-space: nowrap;
}

.DragHandle {
    /* flex: 0 0 16px;
    z-index: 2; */
    cursor: col-resize;
    /* color: #0085ff;
    right: 0;
    position: absolute; */
}

.DragHandle:hover {
    background-color: rgba(0, 0, 0, 0.1);
}

.DragHandleActive,
.DragHandleActive:hover {
    color: #0b6fcc;
    z-index: 3;
}

.DragHandleIcon {
    flex: 0 0 12px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.fresler-grouped-table {
    padding-top: 20px;
    padding-bottom: 20px;
}

.fresler-table-group-item-area {
    padding-top: 20px;
    padding-bottom: 20px;
}


.fresler-col-header-cell {
    border-right-width: 1px;
}


.fresler-table-cell {
    display: table-cell;
    padding: 10px;
}

.fresler-grouped-table-row {
    border-top: 1px solid rgba(0, 0, 0, 0.08);
}

.fresler-dropdown-panel ul {
    position: static !important;
}

.fresler-grouped-table-row {
    border-top: 1px solid rgba(0, 0, 0, 0.08);
}

.grouped-table-header-text {
    font-weight: bold;
    display: flex;
}

.grouped-table-header-text-label {
    width: 100%;
    min-width: 300px;
    white-space: nowrap;
    font-size: x-large;
}

.grouped-table-header-text input {
    font-weight: bold;
}

.grouped-table-header-render {
    max-width: 40%;
}

.fresler-table-select-filter-option-list {
    max-height: 200px;
    overflow-y: auto;
}

/* dsd/÷ */
.fresler-grouped-table-cell,
.fresler-table-cell {
    display: table-cell;
    padding: 10px;

    border-right: 1px solid rgba(0, 0, 0, 0.08);
    border-bottom: 1px solid rgba(0, 0, 0, 0.08);
    vertical-align: middle;
}

.fresler-table-row.fresler-table-row-add-new {
    background: none !important;
}


.fresler-grouped-table-row.fresler-table-row-add-new .fresler-table-cell {
    border-bottom: 0;
}

.fresler-table-row-add-new .fresler-table-cell {
    border-right: 0;
    background: none;
}

.fresler-table-row-add-new .fresler-table-cell:last-child {
    border-right: 1px solid rgba(0, 0, 0, 0.08);
}

.fresler-table-row-add-new .fresler-table-cell:hover {
    background: none;
}

.fresler-table-column-header-row .css-13cymwt-control {
    border: 0;
    border-bottom: 2px solid rgba(0, 0, 0, 0.18);
    border-radius: 0;
    background: transparent;
}

.fresler-table-row-select-checkbox {
    text-align: center;
}

.fresler-grouped-table-cell .form-control,
.fresler-ungrouped-table-row .form-control,
.fresler-grouped-table-row .form-control,
.fresler-table-top-header .form-control {
    font-weight: 400;
    border: 0;
    border-radius: 0;
    background: transparent;
    box-shadow: none;
    padding-left: 0px;
    padding-right: 0;
}

.fresler-table-top-header:hover .form-control,
.fresler-table-top-header .form-control:focus,
div.css-13cymwt-control:hover {
    border-bottom: 2px solid rgba(0, 0, 0, 0.18) !important;
}

.fresler-grouped-table-cell .select__control,
.fresler-table-cell .select__control,
.fresler-table-cell .react-datepicker-wrapper,
.fresler-col-header-cell .select__control,
.css-13cymwt-control {
    border: 0;
    border-radius: 0 !important;
    box-shadow: none;
    background-color: transparent !important;
    border-style: none !important;
}

.fresler-grouped-table-cell:hover,
.fresler-table-cell:hover {
    background: rgba(0, 0, 0, 0.05);
}

.fresler-grouped-table-cell .form-control:focus,
.fresler-grouped-table-cell .select__control:focus,
.fresler-grouped-table-cell:hover .form-control,
.fresler-grouped-table-cell:hover .select__control,
.fresler-table-cell .form-control:focus,
.fresler-table-cell .select__control:focus,
.fresler-table-cell:hover .form-control,
.fresler-table-cell:hover .select__control {
    border-color: #b6aee9;
    border-bottom: 1px solid rgb(204, 204, 204);
}

.fresler-grouped-table-cell .select__control .css-hlgwow,
.fresler-table-cell .select__control .css-hlgwow {
    padding-left: 0;
}

.fresler-table-more-options-section .dropdown {
    display: inline;
}

.fresler-col-header-check svg {
    width: 17px;
    height: 17px;
}

.fresler-grouped-table-cell,
.fresler-table-cell {
    vertical-align: middle;
}

.fresler-col-header {
    align-items: center;
    font-size: medium;
}

.fresler-table-col-drag-handle-area {
    align-self: center !important;
    margin-left: auto;
}

.fresler-table-row.fresler-table-column-header-row,
.fresler-table-top-header {
    background-color: rgb(0, 0, 0, 0.01);
    flex: 0 0 auto;
}

.fresler-table-top-header {
    padding: 10px;
    margin-left: 0px;
    margin-right: 0px;
    align-items: center;
}

.fresler-table-top-header .form-control {
    border-bottom: 2px solid rgba(0, 0, 0, 0);
    font-size: medium;
}

.search-all-input {
    border: 1px solid rgb(204, 204, 204);
    border-radius: 50rem;
    background-color: transparent;
}

.search-all-input:focus {
    box-shadow: none;
}

.fresler-table-group-by-display svg {
    width: 18px;
    height: 18px;
}

.fresler-grouped-table-row:nth-of-type(odd),
.fresler-table-row:nth-of-type(even) {
    background-color: rgba(0, 0, 0, 0.03);
}

.fresler-table-row:nth-of-type(even) .fresler-grouped-table-row:hover,
.fresler-table-row:hover {
    background-color: rgba(0, 0, 0, 0.05);
}

.select__single-value img.avatarImg {
    margin-right: 7px;
}

.fresler-table-more-options-section>span>svg {
    width: 22px;
    height: 20px;
    margin-bottom: 4px;
}

.progress-bar-container>div {
    display: flex;
    align-items: center;
    gap: 7px;
    flex-direction: row-reverse;
}

.form-check-input {
    height: 1.2em;
    width: 1.2em;
}

.fresler-dropdown-toggle {
    width: 20px;
}

.react-datepicker-wrapper input {
    border: 0;
    border-radius: 0;
    box-shadow: none;
    background-color: transparent;
}

.fresler-table-cell:hover .react-datepicker-wrapper input,
.react-datepicker-wrapper input:focus {
    border-bottom: 1px solid rgba(0, 0, 0, 0.08);
}

.dropdown-toggle::after {
    content: none !important;
}

.dropdown-toggle {
    background: transparent;
    color: #323232 !important;
    padding: 2px;
    border-color: transparent !important;
    border-radius: 2px;
}

.fresler-table-window {
    height: 100%;
    width: 100%;
    overflow: auto;
}

.dropdown-menu.show {
    position: absolute;
    top: 100%;
    left: 0;
    z-index: 4000;
    background-color: white;
    border: 1px solid #ddd;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
    width: fit-content;
}

::-webkit-scrollbar {
    width: 7px;
    /* Adjust width for vertical scrollbar */
    height: 7px;
    /* Adjust height for horizontal scrollbar */
}

::-webkit-scrollbar-thumb {
    background: #888;
    /* Change scrollbar color */
    border-radius: 10px;
    /* Roundness of the scrollbar */
}

::-webkit-scrollbar-thumb:hover {
    background: #555;
    /* Color on hover */
}

::-webkit-scrollbar-track {
    background: #f1f1f1;
    /* Background of the scrollbar track */
}
.tracker-board-task-cell {
    min-width: 30px;
    min-height: 40px;

    display: flex;
    align-items: center;
    height: 200px;
}

.fresler-table-cell-task-wrapper {
    padding: 0 !important;
}

.tracker-board-task-cell-icon-set {
    text-align: center;
}

.tracker-board-task-cell {}

.tracker-board-task-cell .dropdown {
    min-width: 30px;
    min-height: 40px;
    height: 100%;
    width: 100%;
}

.tracker-board-task-cell .dropdown>div {
    min-width: 30px;
    min-height: 40px;
    height: 100%;
    width: 100%;

    align-items: center;
    display: flex;
}


.pulse-icon {

    -webkit-animation: pulse 1s infinite alternate;
}

@keyframes pulse {
    0% {
        transform: scale(1);
        /* Start size */
    }

    100% {
        transform: scale(1.2);
        /* End size (adjust as needed) */
    }
}

.tracker-board-contact-cell:hover {
    cursor: pointer;
    border-bottom: 1px solid #303030 !important
}

.tracker-board-tracker-title-editicon {
    display: none;
    cursor: pointer;
}

.tracker-board-tracker-title:hover .tracker-board-tracker-title-editicon {
    display: inline-block;
}
.trackerboard-taskpanel-table {
    display: table;
    width: 100%;
    padding-top: 20px;
    padding-bottom: 20px;
}

.trackerboard-taskpanel-table-row {
    display: table-row;
}

.trackerboard-taskpanel-table-cell {
    display: table-cell;
    padding: 10px;
}

.st-complete {
    text-decoration: line-through;
    color: #999;
}

.avatar-image {
    border-radius: 50%;
    border-color: white;
    border-width: 80px;
}


.left-comment-area {
    flex: 0 0 60px;
    /* Flexible but with a fixed initial size of 200px */
    display: flex;
    /* Use flexbox layout */
    justify-content: center;
    /* Center horizontally */
    align-items: center;
    /* Center vertically */
}

.right-comment-area {
    flex: 1;
    /* Flexible, takes up remaining space */
}

textarea {
    width: 98%;
    box-sizing: border-box;
    /* Ensure padding and border are included in width */
}

.taskpanel-card {
    margin-bottom: 0px;
    height: 100%;
}

.task-panel-bottom-area {
    width: 100%;
}

.fresler-table-task-panel-label {
    min-width: 75%;
}

.fresler-table-task-panel-label .card-title-wrapper {
    width: 100%;
}

.slim-input {
    border-bottom: 2px solid transparent;
    border: 0;
    background-color: transparent !important;

    color: #323232;
    background-color: #f8f9fa;
    border-color: unset;
    outline: 0;
    -webkit-box-shadow: unset !important;
    box-shadow: unset !important;
}

.slim-input:focus,
.slim-input:hover {
    border-bottom: 2px solid rgba(0, 0, 0, 0.18);
    border-radius: 0%;
    color: unset;
    background-color: unset;
    border-color: unset;
    outline: unset;
    -webkit-box-shadow: unset;
    box-shadow: unset;
}

.react-datepicker-wrapper {
    padding-left: 8px;
    width: 100%;
}

.react-datepicker__input-container input {
    border-bottom: 2px solid transparent;
    width: 100%;
}

.react-datepicker__input-container input:hover,
.react-datepicker__input-container input:hover {
    border-bottom: 2px solid rgba(0, 0, 0, 0.18);
}

.fresler-table-task-panel-footer {
    padding: 5px;
}

.left-comment-area {
    padding-left: 0%;
    padding-right: 0%;
}
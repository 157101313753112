table.fresler-table-column-selection-table {
    width: 100%;
}

.fresler-table-column-selection-table th,
.fresler-table-column-selection-table td {
    /* width: 20%; */
    /* Adjust this as needed */
    padding: 8px;
    text-align: left;
}

.fresler-table-column-selection-table th:last-child,
.fresler-table-column-selection-table td:last-child {
    width: 30%;
    /* Adjust this as needed */
}

.fresler-table-column-selection-table th:last-child,
.fresler-table-column-selection-table td:last-child button {
    width: 100%;
}

.move-col,
.delete-col {
    width: 10px !important;
}
.fresler-table-column-header-filter-dropdown {
  position: relative;
}

/* Dropdown toggle button */
.dropdown-toggle {
  background: none;
  border: none;
  cursor: pointer;
  padding: 0;
  display: flex;
  z-index: 1050;
  align-items: center;
}

/* Dropdown menu */
.dropdown-menu {
  position: fixed;
  background-color: #ffffff;
  border: 1px solid #e0e0e0;
  border-radius: 4px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  z-index: 1050;
  min-width: 150px;
  padding: 8px 0;
}

/* Dropdown item */
.dropdown-item {
  display: flex;
  align-items: center;
  padding: 8px 16px;
  cursor: pointer;
  transition: background-color 0.2s ease;
}

.dropdown-item-no-hover:hover { 
  background-color: unset !important;
  color: unset !important;
  cursor: unset !important;
}

/* Dropdown item icon */
.dropdown-item-icon {
  margin-right: 8px;
}

/* Ensure the dropdown is above other elements */
.dropdown-menu,
.dropdown-toggle {
  z-index: 1050;
}

/* If you need to adjust the dropdown position */
.fresler-dropdown-toggle {
  margin-left: auto; /* Push to the right side of the cell */
}
.split-pane-col {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
}

.split-pane-row {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: row;
}

.split-pane-top,
.split-pane-bottom,
.split-pane-right,
.split-pane-left {
    flex: 1;
    overflow: hidden;
}

.split-pane-right {}

.split-pane-top,
.split-pane-bottom {
    background-color: #ccc;
    text-align: left;
    padding: 2%;
}

.separator-col {
    border-left: 2px solid rgb(135, 135, 135);
    border-right: 2px solid transparent;
    height: 100%;
}

.separator-row {
    border: 1px solid rgb(135, 135, 135);
    cursor: row-resize;
}

.separator-col-wrapper {
    cursor: col-resize;
    height: 100%;
    padding-left: 8px;
    padding-right: 8px;
}

.separator-col-wrapper:hover  .separator-col,
.separator-col:focus {
    border: 2px solid blue;
    height: 100%;
}
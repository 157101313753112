.hover-underline:hover {
    text-decoration: underline;
}

.form-label {
    font-size: larger;
}

.form-check-input {
    font-size: larger;
    /* margin-left: -2.2rem !important; */
}

.watermark-options {
    color: black !important;
    border: black;
    border-width: thin;
    /* border-style: dashed; */
    border-radius: 5px;
    padding: 0.5rem;
}

.watermark-options .form-label {
    color: black;
    font-weight: 300;
}

.pdf-preview {
    border-style: solid;
    border-width: thin;
    border-color: black;
}

.pdf-watermark-tail {
    margin-bottom: 4rem !important;
}

.app-wrapper-ai {
    flex-shrink: 1 !important;
    flex-grow: 1 !important;
    flex-direction: column !important;
    display: flex !important;
}